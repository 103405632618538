import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Row, Col } from 'antd'
import { useRequest } from 'ahooks'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Helmet } from '@/components'
import { Box, useMediaQuery } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import { Form, FormItem, FormItemProps } from '@/components/EnquireForm/Form'
import { APIqueryBizDictData } from '@/components/EnquireForm/api'
import { APIgetCareersCityByCountry } from '@/components/Careers/api'
import { useTheme } from '@mui/material/styles'
import { fetchCareers } from '@/components/Careers/api'
import { Footer, Header, SEO } from '../components'
import CoreValues from '../components/Careers/CoreValues'
import CardCommon from '../components/Careers/CardCommon'
import Empty from '../components/Empty'
import useCareersSelectAndDrawer from '../components/Careers/CareersPhoneSelectDrawer'
import { GlobalAntdFormStyle } from '../components/GlobalCSS/AntdFormStyle'
import Search from '../components/Search'

//初始化分页参数
const INIT_PAGINATION = {
  totalPage: 1,
  currentPage: 1,
  totalResult: 1,
  showCount: 9,
}

const COMMON_DISPOSITION = {
  // required: true,
  optionLabel: 'value',
  optionValue: 'key',
}

const CARD_PC_COL = {
  xs: 12,
  sm: 4,
  md: 4,
}

const CARD_PHONE_COL = {
  xs: 12,
  sm: 12,
  md: 12,
}
//初始化搜索参数
const INIT_SEARCH_PARAMS = {
  titleOrBody: '',
  category: 'ALL',
  country: 'ALL',
  city: 'ALL',
  currentPage: 1,
}

const INIT_OPTIONS = {
  key: 'ALL',
  value: 'ALL',
}

export default (props) => {
  const [careersList, setCareersList] = useState<Record<string, any>[]>([])
  const [pagination, setPagination] = useState(INIT_PAGINATION)
  const [searchParams, setSearchParams] = useState(INIT_SEARCH_PARAMS)
  const [category, setCategory] = useState<Record<string, any>[]>([INIT_OPTIONS])
  const [country, setCountry] = useState<Record<string, any>[]>([INIT_OPTIONS])
  const [city, setCity] = useState<Record<string, any>[]>([])
  const [showMore, setShowMore] = useState<boolean>(true)
  const phoneCurrentPageRef = useRef<number>(1)
  const moreFlagRef = useRef<boolean>(false)
  const [form] = Form.useForm()
  const {} = useTranslation()
  const pageLocale = JSON.parse(props.data.locales.edges[0].node.data)
  const commonLocale = JSON.parse(props.data.commonLocale.data)
  const seoLocale = JSON.parse(props.data.seoLocale.data)
  const isPc = useMediaQuery('@media (min-width: 800px)')
  const theme = useTheme()
  const { direction } = theme

  const { run: getCareers } = useRequest(fetchCareers, {
    onSuccess: (res) => {
      setShowMore(true)
      if (res?.resultObject?.results.length > 0) {
        if (isPc || !moreFlagRef.current) {
          setCareersList(res.resultObject.results)
        } else {
          setCareersList((_) => [..._, ...(res.resultObject.results || [])])
        }
        setPagination(res.resultObject.pagination)
      } else {
        if (isPc || !moreFlagRef.current) {
          setCareersList(res.resultObject?.results || [])
          setPagination(INIT_PAGINATION)
        } else {
          setCareersList((_) => [..._, ...(res.resultObject.results || [])])
          setShowMore(false)
        }
      }
    },
  })

  const { run: getCategory } = useRequest(APIqueryBizDictData, {
    manual: true,
    onSuccess: (res) => {
      if (res?.resultObject?.CareerCategory?.length > 0) {
        setCategory([INIT_OPTIONS, ...(res.resultObject.CareerCategory || [])])
      } else {
        setCategory([INIT_OPTIONS])
      }
    },
  })

  const { run: getCountry } = useRequest(APIqueryBizDictData, {
    manual: true,
    onSuccess: (res) => {
      if (res?.resultObject?.WebCountry?.length > 0) {
        const countryList = res.resultObject.WebCountry.map((item) => ({
          ...item,
          countryName: item.key,
        }))
        setCountry([{ countryName: 'ALL' }, ...(countryList || [])])
      } else {
        setCountry([{ countryName: 'ALL' }])
      }
    },
  })

  const { run: getCity } = useRequest(APIgetCareersCityByCountry, {
    manual: true,
    onSuccess: (res) => {
      if (res?.resultObject?.length > 0) {
        //城市的selectOption数据返回是string[],需要格式化字段
        const formatList = res.resultObject?.map((item) => ({ value: item, key: item })) || []
        setCity(formatList)
      } else {
        setCity([])
      }
    },
  })

  const handleClick = useCallback(() => {
    phoneCurrentPageRef.current += 1
    moreFlagRef.current = true
    getCareers({ ...searchParams, currentPage: phoneCurrentPageRef.current })
  }, [searchParams, getCareers])

  const formList: FormItemProps[] = [
    {
      type: 'Select',
      name: 'Category',
      label: '',
      data: category,
      SelectProps: {
        onSelect: (e) => {
          getCareers({
            ...searchParams,
            category: e || '',
          })
          setSearchParams({
            ...searchParams,
            category: e || '',
          })
          moreFlagRef.current = false
        },
      },
      ...COMMON_DISPOSITION,
    },
    {
      type: 'Select',
      name: 'Country',
      label: '',
      data: country,
      optionLabel: 'countryName',
      optionValue: 'countryName',
      SelectProps: {
        onChange: (e) => {
          getCareers({
            ...searchParams,
            country: e || '',
          })
          setSearchParams({
            ...searchParams,
            country: e || '',
          }),
            form.setFieldValue('City', [])
          getCity(e === 'ALL' ? '' : e)
          moreFlagRef.current = false
        },
      },
    },
    {
      type: 'Select',
      name: 'City',
      label: '',
      data: city,
      showSearch: true,
      SelectProps: {
        mode: 'multiple',
        onChange: (e) => {
          const valueString: string = e?.toString() || ''
          getCareers({
            ...searchParams,
            city: valueString,
          })
          setSearchParams({
            ...searchParams,
            city: valueString,
          })
          moreFlagRef.current = false
        },
      },
      ...COMMON_DISPOSITION,
    },
  ]

  const { content: drawerContent } = useCareersSelectAndDrawer({
    searchParams,
    setSearchParams,
    getCareers,
    getCity,
    moreFlagRef,
    typeOptions: {
      country: (country as Record<string, any>[])?.map((item) => ({
        value: item.countryName,
        key: item.countryName,
      })),
      city,
      category,
    },
  })

  useEffect(() => {
    getCategory('CareerCategory')
    getCountry('WebCountry')
    getCity('')
  }, [])

  useEffect(() => {
    document.querySelector('.banner')?.scrollIntoView({})
  }, [])

  return (
    <>
      <SEO
        title={seoLocale.careers.title}
        description={seoLocale.careers.description}
        pageLocale={pageLocale}
      />
      <Helmet>
        <meta
          name='keywords'
          content='Careers at iMile, iMile jobs, Work for iMile, iMile delivery job, job opportunities iMile, Work at iMile'
        />

        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='iMile Delivery' />
        <meta property='og:url' content='https://www.imile.com/careers/' />
        <meta
          property='og:image'
          content='https://www.imile.com/static/1c763be06e4165a95c46d50a3039cf28/d5f9a/banner.webp'
        />
        <meta property='og:title' content='A career with IMile' />
        <meta
          property='og:description'
          content='Careers at iMile - Discover the job opportunities available at iMile. Apply now to be a part of iMile'
        />
      </Helmet>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      {/* banner*/}
      <Box
        className='banner'
        sx={{
          position: 'relative',
          '.bg-img': {
            height: ['calc(100vh - 55px)', 'calc(100vh - 55px)', '555px', '705px'],
            mt: ['55px', '55px', '90px'],
            width: 1,
          },
        }}
      >
        {isPc ? (
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/careers/banner.png'
            alt='About iMile Delivery'
            className='bg-img'
          />
        ) : (
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/careers/banner-mobile.png'
            alt='About iMile Delivery'
            className='bg-img'
          />
        )}

        <Search
          buttonTxt={commonLocale.search}
          placeHolder={pageLocale.searchTips}
          homebannersx={{
            position: 'absolute',
            bottom: ['104px', 0],
            left: '50%',
            transform: 'translate(-50%)',
            bgcolor: 'unset',
          }}
          callback={(e) => {
            getCareers({
              ...searchParams,
              titleOrBody: e,
              currentPage: 1,
            })
            moreFlagRef.current = false
            setSearchParams((_) => ({
              ..._,
              titleOrBody: e,
            }))
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: ['60%', '65%', '50%'],
            transform: 'translate(0, -40%)',
            left: ['16px', '80px'],
            textAlign: 'left',
            color: '#fff',
            '.tit': {
              whiteSpace: 'pre-line',
              fontSize: 'var(--slogan-tit-font-size)',
              fontWeight: 800,
            },
            '.desc': {
              whiteSpace: 'pre-line',
              fontSize: 'var(--slogan-desc-font-size)',
            },
          }}
        >
          <h1 className='tit'>{pageLocale.Slogan}</h1>
          <div className='desc'>{pageLocale.SloganDesc}</div>
        </Box>
      </Box>

      {/*卡片区域 */}
      {isPc ? (
        <>
          <GlobalAntdFormStyle />
          <Box
            className='career-box'
            sx={
              {
                display: 'flex',
                pt: ['60px'],
                pm: ['56px', '32px'],
                px: 'calc(var(--page-px))',
                maxWidth: 'var(--page-width)',
                margin: '0 auto',
                '.select-box': {
                  display: 'flex',
                  width: '100%',
                  '.MuiBox-root': {
                    flex: '1',
                    marginBottom: '36px',
                    '.label': {
                      width: '98px',
                      height: '48px',
                      fontSize: [16],
                      borderRadius: direction === 'rtl' ? '0 8px 8px 0' : '8px 0 0 8px',
                      color: '#000417',
                      background: '#ebeff5',
                      lineHeight: '48px',
                      textAlign: 'center',
                    },
                    '.col-span': {
                      display: 'flex',
                      width: '100%',
                      padding: '0 10px',
                      '.ant-form-item': {
                        flex: 1,
                      },
                    },
                    '.ant-select-multiple': {
                      '.ant-select-selector': {
                        overflow: 'auto !important',
                      },
                    },
                    '.ant-select-selector': {
                      height: '48px !important',
                      borderRadius: '0 8px 8px 0 !important',
                    },
                  },
                },
              } as any
            }
          >
            <div className='select-box'>
              <Form
                form={form}
                name='form_in_modal'
                initialValues={{
                  Category: 'ALL',
                  Country: 'ALL',
                }}
              >
                <Row>
                  {formList.map((formItem) => (
                    <Col span={8} className='col-span'>
                      <div className='label'>{formItem.name}</div>
                      <FormItem {...formItem} key={formItem.name} />
                    </Col>
                  ))}
                </Row>
              </Form>
            </div>
          </Box>
          <Box
            sx={{
              position: 'relative',
              minHeight: ['426px'],
            }}
          >
            {careersList?.length ? (
              <CardCommon list={careersList} col={CARD_PC_COL} openBlank={true} />
            ) : (
              <Empty
                blogs={[]}
                locale={{
                  ...pageLocale,
                  searchEmpty: `${pageLocale.searchEmpty1} "${searchParams.titleOrBody}"<br />${pageLocale.searchEmpty2}`,
                }}
                titleOrBody={searchParams.titleOrBody}
              />
            )}
          </Box>
        </>
      ) : (
        <>
          <Box
            className='career-box'
            sx={{
              position: 'relative',
              px: 'var(--page-px)',
              maxWidth: 'var(--page-width)',
              minHeight: '246px',
            }}
          >
            <div className='select-box'>{drawerContent}</div>
            {careersList?.length ? (
              <CardCommon list={careersList} col={CARD_PHONE_COL} openBlank={true} />
            ) : (
              <Empty
                blogs={careersList}
                locale={pageLocale}
                titleOrBody={searchParams.titleOrBody}
              />
            )}
          </Box>
        </>
      )}

      {isPc ? (
        <Stack
          spacing={2}
          sx={{
            '.MuiPagination-ul': {
              px: 'var(--page-px)',
              maxWidth: 'var(--page-width)',
              justifyContent: ['center', 'flex-end'],
              '.Mui-selected': { color: '#095ef7' },
              '.MuiPagination-ul': {
                margin: '0 auto',
              },
            },
          }}
        >
          {careersList.length > 0 && (
            <Pagination
              page={pagination.currentPage}
              count={Math.ceil(pagination.totalResult / pagination.showCount)}
              onChange={(e, value) => {
                setPagination({
                  ...pagination,
                  currentPage: value,
                })
                getCareers({
                  ...pagination,
                  ...searchParams,
                  currentPage: value,
                })
                moreFlagRef.current = false
                document.querySelector('.career-box')?.scrollIntoView({})
              }}
            />
          )}
        </Stack>
      ) : showMore && careersList.length ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '1.4rem',
          }}
        >
          <Box
            sx={{
              width: '5rem',
              fontSize: '14px',
              textAlign: 'center',
              boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.06)',
              borderRadius: '0.8rem',
              cursor: 'pointer',
              fontWeight: 500,
            }}
            onClick={handleClick}
          >
            More
          </Box>
        </Box>
      ) : null}
      <CoreValues title={pageLocale.title} locale={pageLocale} />
      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["careers"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
