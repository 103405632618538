// 时间戳转显示格式dd/mm/yyyy
export const formatDate = (date: number) => {
  if (date || date === 0) {
    const dateObj = new Date(date)
    const y = dateObj.getFullYear()
    const m = dateObj.getMonth() + 1
    const d = dateObj.getDate()
    return `${d}/${m}/${y}`
  }
  return ''
}
