import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useI18next } from '@/components'
import { Box, useMediaQuery } from '@mui/material'
import { formatDate } from './utils'

const CardCommon = ({ list, col, openBlank }) => {
  const { navigate } = useI18next()
  const isPc = useMediaQuery('@media (min-width: 800px)')

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      sx={{
        width: '100% !important',
        px: 'var(--page-px)',
        maxWidth: 'var(--page-width)',
        mx: 'auto !important',
        mb: ['52px'],
        padding: isPc ? '0 40px' : '0',
      }}
    >
      {list.map((oItem): any => (
        <Grid
          item
          key={oItem.id}
          sx={{ px: '10px !important', cursor: 'pointer', width: '100%' }}
          {...col}
        >
          <Card
            key={oItem.id}
            sx={{
              position: 'relative',
              m: '0 auto',
              borderRadius: '16px',
              minWidth: '210px',
              fontSize: [16],
              minHeight: [222, 222],
              maxHeight: [222, 222],
              background: '#fff',
              boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => {
              if (openBlank) {
                window.open(`/careersDetail?id=${oItem.id}`)
              } else {
                navigate(`/careersDetail`, {
                  state: {
                    title: oItem.tittle.toLowerCase(),
                    id: oItem.id,
                  },
                })
              }
            }}
          >
            <CardContent>
              <Typography
                gutterBottom
                variant='h5'
                component='div'
                sx={{
                  marginTop: '10px',
                  fontSize: [16, 22],
                  minHeight: [30, 30],
                  lineHeight: '30px',
                  color: '#000417',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {oItem.tittle}
              </Typography>
              <Typography
                gutterBottom
                variant='h5'
                component='div'
                sx={{
                  display: 'flex',
                  fontSize: [16],
                  fontFamily: 'unset',
                  fontWeight: 500,
                  color: '#5b5c73',
                  mt: ['12px'],
                  mb: ['12px'],
                  '.category-text': {
                    maxWidth: '50%',
                    borderRadius: '8px',
                    backgroundColor: '#005EDF',
                    color: '#fff',
                    padding: '8px 12px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  },
                }}
              >
                <div className='category-text' title={oItem.category}>
                  {oItem.category}
                </div>
                <Box
                  sx={{
                    maxWidth: '50%',
                    marginLeft: '10px',
                    borderRadius: '8px',
                    background: 'rgba(0, 94, 223, 0.2)',
                    color: '#005EDF',
                    padding: '8px 12px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  title={oItem.jobTypeName}
                >
                  {oItem.jobTypeName}
                </Box>
              </Typography>
              <Box
                sx={{
                  width: 'max-content',
                  maxWidth: '100%',
                  color: '#000417',
                  borderRadius: '8px',
                  backgroundColor: '#EBEFF5',
                  padding: '8px 12px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {oItem.city}
              </Box>
              <Box
                sx={{
                  color: '#5B5C73',
                  fontSize: '14px',
                  marginTop: '20px',
                }}
              >
                {`Update Time: ${formatDate(oItem.lastUpdDate)}`}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

export default CardCommon
